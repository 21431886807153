import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../../components";
import Block from "../../components/cta.js";
import VizPage from "../../components/vizPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <VizPage heading="Eviction Filings by Lead Plaintiff" mdxType="VizPage">
        <iframe src="https://public.tableau.com/shared/JBY8Y53Q4?:display_count=yes&:origin=viz_share_link?:showVizHome=no&:embed=true" allowFullScreen="true" width="1015" height="835"></iframe>
        <br></br>
        <h2>{`About`}</h2>
        <br></br>
        <p>{`This map displays eviction filings by top lead plantiff.`}</p>
        <br></br>
        <h2>{`Map Filter and Selection`}</h2>
        <br></br>
        <dl> This visualization allows a user to apply a variety of filters to refine the map. </dl>
        <dl>    
    <dt> <strong>Eviction Filings: </strong> filter parcels by the number of evictions filed at a given property.</dt>
    
    <dt> <strong>Eviction Year: </strong> only view eviction filings by a given year.</dt>
    
    <dt> <strong>Eviction Month: </strong> view eviction filings by a given month.</dt>
        
    <dt> <strong>Lead Plaintiff: </strong> search by the lead plaintiff in each court record. Deselect all, search for a given plaintiff name, and click apply.</dt>
    
    <dt> <strong>Land Use: </strong> view parcels by current land use.</dt>
    
    </dl>
        <br></br>
        <h2>{`Data Sources`}</h2>
        <br></br>
        <p><strong parentName="p">{`Master Property File`}</strong></p>
        <br></br>
        <p>{`Information on current land use and listed property owners retrieved from the City of Milwaukee's Master Property File (MPROP). MPROP as it is commonly called, is a table containing a record for each property in the city. It contains more than 90 elements of data describing each of the approximately 160,000 properties in the city. More information can be found `}<a parentName="p" {...{
            "href": "https://data.milwaukee.gov/dataset/mprop"
          }}>{`here.`}</a></p>
        <br></br>
        <p><strong parentName="p">{`Eviction Records`}</strong></p>
        <br></br>
        <p>{`Eviction data is pulled from the WCCA REST Interface — programmatic access to Wisconsin Circuit Court records. Small claims cases in Milwaukee County with a case type of small claims eviction are pulled down and stored on a weekly basis. CCAP provides no warranties as to the accuracy or timeliness of the information contained in the WCCA Data.`}</p>
      </VizPage>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      